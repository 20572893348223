





















































































































































import { ErrorManager } from '@/models/error';
import { Shipment } from '@/models/shipment';
import { axios } from '@/plugins/axios';
import { FormRules } from '@/utils/formRules';
import { cloneDeep } from 'lodash';
import Vue from 'vue';
import { mapActions } from 'vuex';
import pdf from 'vue-pdf';

export default Vue.extend({
  name: 'Estimate',
  mixins: [FormRules],
  components: {
    pdf,
  },
  data() {
    return {
      dialog: false,
      valid: false,
      loading: false,
      invoice: null as any,
      errorMessage: '',
      selectedShipment: new Shipment(),
    };
  },
  computed: {
    errorOccurred(): boolean {
      return this.errorMessage.length > 0;
    },
  },
  methods: {
    ...mapActions({
      updateShipment: 'shipment/updateShipment',
      uploadInvoice: 'shipment/uploadInvoice',
    }),
    open(shipment: Shipment) {
      this.selectedShipment = cloneDeep(shipment);
      this.dialog = true;
    },

    close() {
      this.selectedShipment = new Shipment();
      // TODO: MG 2022-02-07 create onstructor to hable invoice
      this.selectedShipment.invoices = [];
      this.invoice = null;
      this.$emit('genShipment');
      this.dialog = false;
    },

    async updateInvoice() {
      this.loading = true;

      if (this.invoice != null) {
        const formData = new FormData();

        formData.append('package', this.selectedShipment!.id as any);
        formData.append('images', this.invoice);
        await this.uploadInvoice(formData).catch((error) => {
          this.errorMessage = ErrorManager.extractApiError(error);
        });
      }
      await this.updateShipment({
        id: this.selectedShipment.id,
        data: {
          price: !this.selectedShipment.price ? 0 : this.selectedShipment.price,
          package_details: this.selectedShipment.package_details,
        },
      }).catch((error) => {
        this.errorMessage = ErrorManager.extractApiError(error);
      });

      this.loading = false;
      this.close();
    },

    downloadImage(url: string) {
      const fileTypeIndex = url.lastIndexOf('.');
      const fileType = url.slice(fileTypeIndex, url.length);

      axios({
        url: url,
        method: 'GET',
        responseType: 'blob',
      }).then((response) => {
        const fileURL = window.URL.createObjectURL(new Blob([response.data]));
        const fileLink = document.createElement('a');

        fileLink.href = fileURL;
        fileLink.setAttribute(
          'download',
          `${this.selectedShipment.description} image.${fileType}`
        );
        document.body.appendChild(fileLink);

        fileLink.click();
      });
    },
  },
});
